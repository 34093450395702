/* Global Settings */

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
}

.icon {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
  vertical-align: bottom;
  stroke-width: 1.5;
}

html,
body {
  margin: 0;
  scroll-behavior: smooth;
  font-family: Inter, sans-serif;


  .p-inputtext,
  .p-component {
    font-family: Inter, sans-serif;
  }

  .p-dropdown {
    min-height: 34.4px;
  }
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

/* Global Settings */

/* Smallest Font size of 10px. In design, we have 12px minimum which has class text-xs */
.text-xxs {
  font-size: 0.625rem;
}

/* Smallest Font size of 10px. In design, we have 12px minimum which has class text-xs */

/* To make buttons generated through some module like selectmodule smaller */
.text-xs {
  .p-button {
    font-size: 0.75rem;
  }
}

/* To make buttons generated through some module like selectmodule smaller */

.top-4rem {
  top: 4rem;
}

/* To make input full width which are created by some component. */
.w-full {
  .p-inputtext {
    width: 100%;
  }
}

/* To make input full width which are created by some component. */

/* To make font size according to text-sm class for some component. */
.text-sm {
  .p-inputtext {
    font-size: 0.875rem;
  }
}

/* To make font size according to text-sm class for some component. */

/* This class is to remove Header from Modal. Used it to show Modals which has half side of image and half of text. That view is not possible with dialog module because header is a must and can't be hidden */
.no-header-modal {
  .p-dialog-header {
    display: none !important;
  }

  .p-dialog-content {
    padding: 0 !important;
  }
}

.overflow-visible {
  .p-dialog-content {
    overflow-y: visible;
  }
}

/* This class is to remove Header from Modal. Used it to show Modals which has half side of image and half of text. That view is not possible with dialog module because header is a must and can't be hidden */

.no-overflow-content {
  .p-dialog-content {
    overflow-y: hidden !important;
  }
}

/* Removing Overflow hidden from modals */
.overflow-visible {
  .p-dialog-content {
    overflow: visible !important;
  }
}

/* To make dropdown padding 0. We can't mark dropdown-label */
.p-0.p-dropdown {
  .p-dropdown-label {
    padding: 0;
  }
}

/* Reducing Spacing and Font size for <p-calendar> Elements */
.p-datepicker table td>span {
  width: 1.8rem;
  height: 1.8rem;
}

.p-datepicker .p-timepicker span,
.p-datepicker table td>span {
  font-size: 0.875rem;
}

.p-datepicker table td {
  padding: 0;
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 1;
}

/* Reducing Spacing and Font size for <p-calendar> Elements */


/* Adjusting Alignment for Time Period Dropdown Reports Template */
.selected-time-dropdown {
  .p-inputtext {
    position: relative;
    top: 0.2rem;
  }
}

.selected-time-calendar {
  top: 1.1rem;
}

/* Adjusting Alignment for Time Period Dropdown Reports Template */

/* Vertical Align Property of Table */
.vertical-align-top {
  vertical-align: top;
}

/* Vertical Align Property of Table */


/* Sidebar width */
.payment-sidebar {
  max-width: 100%;

  @media (min-width: 600px) {
    max-width: 540px;
  }

  .p-sidebar-content {
    padding: 0;
    position: static;
  }

  .p-sidebar-header {
    padding-bottom: 0;
  }
}

/* Sidebar width */
/* Removing Extra block on left side to make timeline take full space */
.payment-status .p-timeline-event-opposite {
  display: none !important;
}

/* Removing Extra block on left side to make timeline take full space */

/* Adding background to Divider to change default white */
.p-divider.surface-100 .p-divider-content {
  background: var(--surface-100) !important;
}

/* Adding background to Divider to change default white */


/* Changing highlight selection of dropdown from blue to light gray */
.p-dropdown-item.p-highlight {

  .grayscale {
    opacity: 1 !important;
    filter: brightness(0) invert(1);
  }

  .add-new-link,
  .text-gray-600 {
    color: #fff !important;
  }

  .bg-blue-50.text-primary,
  .bg-blue-100.text-primary {
    color: #007bff !important;
  }

  svg {
    fill: #fff !important
  }
}

/* Changing highlight selection of dropdown from blue to light gray */


.custom-marker-dashed+.p-timeline-event-connector {
  position: absolute;
  top: 2rem;
  height: 2.6rem;
  border-left: 2px dashed var(--gray-400);
  background: none;
}

/* Changing highlight selection of dropdown from blue to light gray */
@import "~@mobiscroll/angular/dist/css/mobiscroll.scss";






// Reducing Calendar Font sizes for Fitness Calendar
.show-sidebar-calendar {

  .p-datepicker table {
    margin: 0.3rem 0;
  }

  .p-datepicker .p-datepicker-header {
    padding: 0;
  }

  .p-datepicker .p-timepicker span,
  .p-datepicker table td>span,
  .p-link,
  .p-datepicker table {
    font-size: 12px;
  }


  .p-datepicker table td>span {
    width: 1.5rem;
    height: 1.5rem;
  }

}

/* To Break long word without space */
.break-word {
  word-break: break-all
}

/* To Break long word without space */

/* Fitness Calendar */

.mbsc-calendar-header {
  z-index: 10 !important;
}
.fitness-calendar-fullscreen {
  .mbsc-calendar {
    height: calc(100vh - 3.2rem) !important;
  }
}

.mbsc-timeline-resources.mbsc-ltr, .mbsc-timeline-header-placeholder {
  max-width: 7rem;
}

.mbsc-ios {

  .mbsc-icon-material-arrow-back::before,
  .mbsc-icon-material-arrow-forward::before {
    color: var(--primary-color) !important;
  }
}

/* Fitness Calendar */

/* Background Red Class */
.bg-error {
  background: #dc3545 !important;
}
/* Background Red Class */

@import "~@mobiscroll/angular/dist/css/mobiscroll.scss";

.pac-container {
  z-index: 2000 !important;
}




// html,
// body {
//   height: 100%;
// }

// body {
//   margin: 0;
//   font-family: Roboto, "Helvetica Neue", sans-serif;
// }

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.sales-pipeline-card-preview {
  min-height: 15rem !important;
  height: auto;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.lostOwnDealCont.cdk-drop-list-dragging .lostOwnDealWrap {
  background: #b62b70;
}

.lostOwnDealCont.cdk-drop-list-dragging .sales-pipeline-card-preview {
  position: absolute;
  top: 0;
  left: 0;
  background: transparent !important;
  width: 100%;
  height: 100%;
  min-height: auto !important;
  border: none !important;
}

.sidebaehoverView {
  width: 200px;
  position: absolute !important;
}


/* Red Background */
$red: #dc3545;
.bg-red {
  background: $red !important;
  color: #fff !important;
}
.bg-red-50 {
  background: rgba($red,0.05) !important;
}
.border-red {
  border-color: $red !important;
}
/* Red Background */


/* Higher Z Index class. We have max of 5. In some plugins, to overlap, we need sometimes 10 or even 100 */
.z-100 {
  z-index: 100 !important;
}
/* Higher Z Index class. We have max of 5. In some plugins, to overlap, we need sometimes 10 or even 100 */

.fitness-calendar {
  height: 78vh;
  @media (min-width: 1200px) and (max-width: 1400px) {
    height: 74vh !important;
    .mbsc-calendar-width-md .mbsc-calendar-day-labels .mbsc-calendar-day-inner {
      min-height: 3em !important;
    }
  }
}
